import CryptoJS from 'crypto-js'
import {useState, useEffect} from 'react'
import axios from 'axios'
import useAuth from './hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import useRefreshToken from './hooks/useRefreshToken'
import { CircularProgress } from '@mui/material'

export function LoginComponent(props:any){

    const navigate = useNavigate()
    const refresh = useRefreshToken()

    const {setAuth, auth}:any = useAuth()
    const [validate, setValidate] = useState({email:true, pass:true})
    const [status, setStatus] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        if(auth?.email){
            navigate('/arcalab/videos')
        }
    },[auth])

    function validate_data(email:string, pass:string){
        let isValid = {email:false, pass:false}
        if(email.length>4){
            isValid.email = true
        }
        if(pass.length>4){
            isValid.pass = true
        }
        return(isValid)
    }

    async function handleLogin(){

        const email = document.getElementById("email")! as HTMLInputElement
        const pass = document.getElementById("password")! as HTMLInputElement
        const valObj:any = validate_data(email.value, pass.value)
        let val = true
        Object.keys(valObj).forEach((key)=>{
          if(!valObj[key]){
            val = false
          }
        })
        if(val){
            const newPass = CryptoJS.SHA256(pass.value).toString()
            const options = {
                url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/auth/auth',
                method:'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data:{
                    email: email.value.toUpperCase(),
                    password: newPass
                },
                // withCredentials: true
            }
            setIsLoading(true)
            await axios.request(options)
            .then((res)=>{
                if(res.status==200){
                    const accessToken = res.data.accessToken
                    const roles = res.data.roles
                    setAuth({email: email.value, password: newPass, roles: roles, accessToken: accessToken})
                    navigate('/arcalab/videos')
                }
            })
            .catch((err)=>{
                setIsLoading(false)
            })
        } else {
            setValidate(valObj)
        }
    }

    const inputStyle =(width='20vw',valid=true)=> {
        return(
            {
        backgroundColor:'#fff',
        padding:'12px',
        border:`1px solid ${valid?'#c7c7c7':'#fd3939'}`,
        outline:'none',
        borderRadius:'10px',
        color:'#000',
        height:'20px',
        width:width,
            }
        )
    }

    function MainLogin(){
        return(
            <div style={{fontFamily:'Inter', fontWeight:'600', textAlign:'center'}}>
                <div>
                    <div style={{textAlign:'left'}}>E-mail</div>
                    <div>
                        <input type='text' id='email' style={inputStyle(props.mobile?'60vw':undefined,validate.email?true:false)} autoComplete='off'/>
                    </div>
                </div>
                <div>
                    <div style={{textAlign:'left'}}>Senha</div>
                    <div>
                        <input type='password' id='password' style={inputStyle(props.mobile?'60vw':undefined,validate.pass?true:false)} autoComplete='off'/>
                    </div>
                </div>
                <div style={{marginTop:'10px', display:'inline-block'}}>
                    {!isLoading?(
                        <div>
                            <div style={{backgroundColor:'#000', color:'#fff', padding:props.mobile?'7px 20px':'10px 30px', cursor:'pointer', float:'left', borderRadius:'20px'}} onClick={()=>{handleLogin()}}>
                                Login
                            </div>
                            <div style={{backgroundColor:'#000', color:'#fff', padding:props.mobile?'7px 20px':'10px 30px', cursor:'pointer', float:'left', marginLeft:'20px', borderRadius:'20px'}} onClick={()=>{navigate('/arcalab/account')}}>
                                Criar Conta
                            </div>
                            <div style={{clear:'both'}}/>
                        </div>
                    ):(
                        <div>
                            <CircularProgress/>
                        </div>
                    )}
                </div>
                {/* <div style={{backgroundColor:'#000', color:'#fff', padding:'10px', width:'100px', cursor:'pointer'}} onClick={()=>{refresh()}}>
                    Refresh
                </div> */}
            </div>
        )
    }

    return(
        <div>
            <div style={{margin:'0 auto', backgroundColor:'#eee', borderRadius:'10px', width:'fit-content', height:'fit-content', textAlign:'left', padding:'30px'}}>
                {status==0&&(<MainLogin/>)}
            </div>
        </div>
    )
}