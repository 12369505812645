import './App.css';
import { Contato } from './components/Contato';
import { MainPage } from './components/Main';
import arca_logo from './images/arca_logo_branca.png'
import { useState, useEffect } from 'react';
import { Solucoes } from './components/Solucoes';
import { Clientes } from './components/Clientes';
import { Publicidade } from './components/publicidade';
import { Arcalab } from './components/Arcalab';
import { ArcalabInscricao } from './components/ArcalabInscricao';
import {Router, Route, Routes, useNavigate} from 'react-router-dom'
import { Helmet } from 'react-helmet';

function App() {

  // const [page, setPage] = useState(0)
  const [mobile, setMobile] = useState(false)
  // const [part, setPart] = useState(0)
  const [cupom, setCupom] = useState('')
  const [verifyMail, setVerifyMail] = useState('')
  const navigate = useNavigate()

  const isMobile = function() {
    return(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  };

  useEffect(()=>{
    setMobile(isMobile())
    if((new URLSearchParams(window.location.search)).get('arcalab')!=null){
      const cup = (new URLSearchParams(window.location.search)).get('cupom')
      const verify = (new URLSearchParams(window.location.search)).get('verificar')
      if(cup!=null){
        setCupom(cup.toUpperCase())
      }
      if(verify!=null){
        setVerifyMail(verify)
      }
      navigate('/arcalab')
    }
  },[])

  const sc_width = window.innerWidth
  let sc_height = window.innerHeight
  if(sc_height>1200){
    sc_height=1200
  }

  const menu = [
    {texto: 'CLIENTES', state:3},
    {texto:'SOLUÇÕES', state:1},
    {texto:'CONTATO', state:2},
  ]

  return (
    <div className="App">
      <Helmet>
        <link href="https://vjs.zencdn.net/8.16.1/video-js.css" rel="stylesheet" />
      </Helmet>
        <Routes>
          <Route path='/' element={<Publicidade height={sc_height} width={sc_width} mobile={mobile}/>}/>
          <Route path='/arcalab/*' element={<Arcalab height={sc_height} width={sc_width} cupom={cupom} mobile={mobile} verifyMail={verifyMail}/>}/>
        </Routes>
    </div> 
  );
}

export default App;
