import CryptoJS from 'crypto-js'
import {useState, useEffect} from 'react'
import axios from 'axios'
import { HTML_Template, HTML_VerifyEmail } from './utils/email_template'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

export function CreateAccount(props:any){

    const [validate, setValidate] = useState({email:true, repeat:true, pass:true})
    const [status, setStatus] = useState(0)
    const [emailMemory, setEmailMemory] = useState('')
    const [codeValid, setCodeValid] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(()=>{
        if(props.verifyMail){
            setEmailMemory(props.verifyMail)
            setStatus(1)
        }
    },[])

    function validate_data(email:string, pass:string, repeat:string){
        let isValid = {email:false, repeat:false, pass:false}
        if(email.length>4){
            isValid.email = true
        }
        if(pass.length>4){
            isValid.pass = true
        }
        if(repeat==pass){
            isValid.repeat = true
        }
        return(isValid)
    }

    async function handleCreate(){

        const email = document.getElementById("email")! as HTMLInputElement
        const pass = document.getElementById("password")! as HTMLInputElement
        const repeat = document.getElementById("repeatpassword")! as HTMLInputElement
        const valObj:any = validate_data(email.value.toUpperCase(), pass.value, repeat.value)
        let val = true
        Object.keys(valObj).forEach((key)=>{
          if(!valObj[key]){
            val = false
          }
        })
        if(val){
            const options = {
                url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/auth/create',
                method:'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data:{
                    email: email.value.toUpperCase(),
                    password: CryptoJS.SHA256(pass.value).toString()
                }
            }
            setIsLoading(true)
            await axios.request(options)
            .then((res)=>{
                if(res.status==200){
                    setIsLoading(false)
                    setStatus(1)
                    setEmailMemory(email.value)
                }
            })
            .catch((err)=>{
                setIsLoading(false)
            })
        } else {
            setValidate(valObj)
        }
    }

    async function handleVerify(){
        let code = ''
        for(let a=0;a<6;a++){
            let code_char = (document.getElementById('verify_char_'+String(a)) as HTMLInputElement).value
            code = code+code_char
        }
        if(code.length==6){
            const options = {
                url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/auth/verify',
                method:'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data:{
                    email: emailMemory.toUpperCase(),
                    code: code
                }
            }
            setIsLoading(true)
            await axios.request(options)
            .then((res)=>{
                navigate('/arcalab/login')
            })
            .catch((err)=>{
                setIsLoading(false)
                setCodeValid(false)
            })
        } else {
            setCodeValid(false)
        }
    }

    const inputStyle =(width='20vw',valid=true)=> {
        return(
            {
        backgroundColor:'#fff',
        padding:'12px',
        border:`1px solid ${valid?'#c7c7c7':'#fd3939'}`,
        outline:'none',
        borderRadius:'10px',
        color:'#000',
        height:'20px',
        width:width,
            }
        )
    }

    function MainCreate(){
        return(
            <div style={{fontFamily:'Inter', fontWeight:'600', textAlign:'center'}}>
                <div>
                    <div style={{textAlign:'left'}}>Email</div>
                    <div>
                        <input type='text' id='email' style={inputStyle(props.mobile?'60vw':undefined,validate.email?true:false)} autoComplete='off'/>
                    </div>
                </div>
                <div>
                    <div style={{textAlign:'left'}}>Senha</div>
                    <div>
                        <input type='password' id='password' style={inputStyle(props.mobile?'60vw':undefined,validate.pass?true:false)} autoComplete='off'/>
                    </div>
                </div>
                <div>
                    <div style={{textAlign:'left'}}>Repetir Senha</div>
                    <div>
                        <input type='password' id='repeatpassword' style={inputStyle(props.mobile?'60vw':undefined,validate.repeat?true:false)} autoComplete='off'/>
                    </div>
                </div>
                <div>
                    {!isLoading?(
                        <div style={{backgroundColor:'#000', color:'#fff', padding:props.mobile?'7px 20px':'10px 30px', width:'100px', cursor:'pointer',borderRadius:'20px', marginTop:'10px', display:'inline-block'}} onClick={()=>{handleCreate()}}>
                        Criar Conta
                    </div>
                    ):(
                        <div>
                         <CircularProgress/>
                        </div>
                    )}
                    
                </div>
            </div>
        )
    }
    
    function Verify(){

        function handleVerifyChange(e:any){
            const numbers =['0','1','2','3','4','5','6','7','8','9']
            const currentArray = Number(e.target.id.charAt(e.target.id.length - 1))
            if(e.target.value.length==1){
                if(numbers.includes(e.target.value)){
                    if(currentArray>0){
                        let newArray = currentArray
                        for(let b=1;b<6;b++){
                            if((document.getElementById('verify_char_'+String(currentArray-b)) as HTMLInputElement).value.length==0){
                                newArray = currentArray-b
                            } else {
                                b=6
                            }
                            if(currentArray-b==0){
                                b=6
                            }
                        }
                        if(newArray!=currentArray){
                            (document.getElementById('verify_char_'+String(newArray)) as HTMLInputElement).value = e.target.value
                            e.target.value = ''
                        }
                        document.getElementById('verify_char_'+String(newArray+1))?.focus()
                    } else {
                        document.getElementById('verify_char_'+String(currentArray+1))?.focus()
                    }
                } else {
                    e.target.value = ''
                }
            }
            if(e.target.value.length>1){
                let lineBeforeClean = e.target.value
                let newLine = ''
                for(let c=0;c<lineBeforeClean.length;c++){
                    if(numbers.includes(lineBeforeClean.charAt(c))){
                        newLine = newLine+lineBeforeClean.charAt(c)
                    }
                }
                if(newLine){
                    for(let a=currentArray;a<6;a++){
                        if(currentArray>0){
                            for(let b=1;b<6;b++){
                                if((document.getElementById('verify_char_'+String(currentArray-b)) as HTMLInputElement).value.length==0){
                                    a=currentArray-b
                                } else {
                                    b=6
                                }
                                if(currentArray-b==0){
                                    b=6
                                }
                            }
                        }
                        (document.getElementById('verify_char_'+String(a)) as HTMLInputElement).value = newLine.charAt(0)
                        newLine = newLine.substring(1)
                    }
                } else {
                    e.target.value=''
                }
            }
        }

        function verifyForm(){
            let elements = []
            for(let a=0;a<6;a++){
                elements.push(<input autoComplete='off' id={'verify_char_'+a} style={{...inputStyle('15px',codeValid?undefined:false), ...{fontFamily:'Inter', fontWeight:'600', textAlign:'center'}}} onChange={(e)=>{handleVerifyChange(e)}}/>)
            }
            return(elements)
        }

        return(
            <div style={{textAlign:'center'}}>
                <div style={{marginBottom:'10px'}}>
                    {verifyForm().map((el, ind)=>{
                        return(
                            <div key={ind} style={{float:'left', marginRight:props.mobile?'1px':'5px'}}>
                                {el}
                            </div>
                        )
                    })}
                    <div style={{clear:'both'}}/>
                </div>
                <div style={{marginBottom:'10px', maxWidth:props.mobile?'80vw':'17vw', fontFamily:'Inter', fontSize:'12px'}}>
                    Para verificar sua conta, copie e cole o código de 6 digitos enviado para o seu e-mail. Lembre-se de checar o Spam ou Lixo Eletrônico.
                </div>
                <div>
                {!isLoading?(
                    <div style={{backgroundColor:'#000', color:'#fff', padding:'10px 30px', cursor:'pointer', borderRadius:'20px', width:'fit-content', display:'inline-block', fontFamily:'Inter', fontWeight:'600'}} onClick={()=>{handleVerify()}}>
                        Verificar E-mail
                    </div>
                ):(
                    <div>
                        <CircularProgress/>
                    </div>
                )}
                </div>
            </div>
        )
    }

    return(
        <div>
            <div style={{margin:'0 auto', backgroundColor:'#eee', borderRadius:'10px', width:'fit-content', height:'fit-content', textAlign:'left', padding:'30px'}}>
                {status==0&&(<MainCreate/>)}
                {status==1&&(<Verify/>)}
            </div>
        </div>
    )
}