import axios from 'axios'
import useAuth from './useAuth'

const useRefreshToken = () =>{
    const {setAuth, auth}:any = useAuth()
    const options = {
        url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/auth/refresh',
        method:'get',
        // withCredentials: true
    }
    const refresh = async()=>{
        await axios.request(options)
        .then((response)=>{
            if(auth?.email){
                setAuth((prev: any) => {
                    return({...prev, accessToken: response.data.accessToken})
                })
            } else {
                setAuth({email: response.data.email, password: response.data.password, roles: response.data.roles, accessToken: response.data.accessToken})
            }
        })
        .catch(()=>{

        })
    }
    return refresh
}

export default useRefreshToken