import { ImageSlider } from "./Slider"
import { LucchiniCompMobile } from "./ArcalabInscricao"
import { LucchiniCompWeb } from "./ArcalabInscricao"
import Fibra_1 from '../images/FIBRA 1.jpg'
import Fibra_2 from '../images/FIBRA 2.jpg'
import Fibra_5 from '../images/FIBRA 5.jpg'
import parte_6_thumb from '../images/thumbs/parte_6_thumb.png'


export function MainPage(props:any){

    function getTransform(maxWidth:number){
        if(props.width<maxWidth){
            return(0)
        } else {
            return((props.width/2)-(maxWidth/2))
        }
    }
    
    function FibraFotosWeb(){
        return(
        <div style={{width:'100%',marginTop:'20px', height:'400px', position:'absolute', left:'0', maxWidth:'1600px', transform:'translate('+getTransform(1600)+'px, 0)'}}>
            <div style={{float:'left', height:'400px', width:'33.333%', backgroundImage:'url("'+Fibra_1+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            <div style={{float:'left', height:'400px', width:'33.333%', backgroundImage:'url("'+Fibra_5+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            <div style={{float:'left', height:'400px', width:'33.334%', backgroundImage:'url("'+Fibra_2+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            <div style={{clear:'both'}}/>
        </div>
        )
    }
    
    function FibraFotosMobile(props:any){
        return(
        <div style={{width:'100%',marginTop:'20px', height:'250px', position:'absolute', left:'0', maxWidth:'1600px', transform:'translate('+getTransform(1600)+'px, 0)'}}>
            {props.id==1&&(
            <div style={{float:'left', height:'250px', width:'100%', backgroundImage:'url("'+Fibra_5+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            )}
            {props.id==2&&(
            <div style={{float:'left', height:'250px', width:'100%', backgroundImage:'url("'+Fibra_1+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            )}
            {props.id==3&&(
            <div style={{float:'left', height:'250px', width:'100%', backgroundImage:'url("'+Fibra_2+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            )}
        </div>
        )
    }

    return(
        <div style={{width:props.mobile?'80vw':'700px', height:'200px', margin:'5vh auto'}}>
                    <div style={{fontFamily:'Digital', color:'#ececec', marginTop:'5vh',marginBottom:'5vh', textAlign:'left', lineHeight:props.mobile?'14px':'20px', fontSize:props.mobile?'10px':'12px'}}>
                        O estudio Fibra e um dos mais renomados do mercado, recebendo diariamente artistas consagrados como Ludmilla, Anitta, L7nnon, Martinho da Vila, Tiago Iorc, entre outros.
                    </div>
                    <ImageSlider mobile={props.mobile} width={props.width}/>

                    <div style={{fontFamily:'Digital', color:'#ececec', marginTop:'5vh', textAlign:'left', lineHeight:props.mobile?'14px':'20px', fontSize:props.mobile?'10px':'12px'}}>
                        Tenha a experiencia de um dia no estudio acompanhando a gravacao de uma banda completa (bateria, baixo, teclado e guitarra), testando equipamentos usados nos maiores estudios do mundo (microfones, pre-amplificadores, compressores e equalizadores) e tecnicas de gravacao e microfonacao, guiado por um engenheiro premiado com diversas indicaCOes ao Latin Grammy.
                    </div>

                    {props.mobile?<LucchiniCompMobile/>:<LucchiniCompWeb/>}

                    <div style={{fontFamily:'Digital', color:'#ececec', textAlign:'left', lineHeight:props.mobile?'14px':'20px', fontSize:props.mobile?'10px':'12px', marginTop:props.mobile?'4vh':'8vh'}}>
                        O laboratorio ofereceu a oportunidade de criar networking e trocar conhecimento com a equipe do estudio, musicos da banda e outros participantes do workshop. Para assistir o conteudo gravado no laboratorio, crie uma conta.
                    </div>
                    {props.mobile?<FibraFotosMobile id={1}/>:<FibraFotosWeb/>}
        </div>
    )
}