import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";
import { useEffect, useRef } from "react";

const RequireAuth=()=>{
    const {auth}:any = useAuth()

    return(
        auth?.email
            ? <Outlet/>
            : <Navigate to='/arcalab/login' state={{from: '/arcalab'}} replace/>
    )

}

export default RequireAuth