import { useNavigate } from "react-router-dom"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LiveTvIcon from '@mui/icons-material/LiveTv';

export function Menu(){
    const navigate = useNavigate()
    return(
        <div style={{position:'absolute', top:'0', padding:'10px', cursor:'pointer'}}>
            <div style={{color:"#fff", float:'left'}}>
                <AccountCircleIcon style={{transform:'scale(1.2)'}} onClick={()=>{navigate('/arcalab/login')}}/>
            </div>
            <div style={{color:"#fff", float:'left', marginLeft:'20px', cursor:'pointer'}}>
                <LiveTvIcon style={{transform:'scale(1.2)'}} onClick={()=>{navigate('/arcalab/videos')}}/>
            </div>
            <div style={{clear:'both'}}/>
        </div>
    )
}